import { createStyles, makeStyles, IconButton, Theme, Box } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { Fragment } from 'react';
import MaterialModal from './MaterialModal';
import ModalContent from './ModalContent';
import ModalTitle from './ModalTitle';
import ModalHeader from './ModalHeader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: '#cdcccc',
            fontSize: 'default',
            padding: 0,
        },
        input: {
            display: 'none',
        },
        contentStyle: {
            margin: theme.spacing(2, 1),
            [theme.breakpoints.down('sm')]: {
                maxHeight: "45vh",
            },
            overflowY: "auto",
            overflowX: "hidden",
        },
    })
);

type Props = {
    helpTitle: string;
    testId?: string;
    helpText: string | JSX.Element;
    className?: string;
    contentStyle?: string;
};

const HelpButton = ({ helpTitle, helpText, testId, className, contentStyle }: Props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={className}>
            <IconButton data-testid={testId || "helpButton"} className={classes.button} aria-label="help" onClick={handleOpen}>
                <HelpOutlineIcon />
            </IconButton>
            <MaterialModal
                open={open}
                onClose={handleClose}
            >
                <Fragment>
                    <ModalHeader showClose={true} onClick={handleClose}>
                        <ModalTitle id="helpModalTitle">
                            <Fragment>
                                {helpTitle}
                            </Fragment>
                        </ModalTitle>
                    </ModalHeader>
                    <ModalContent>
                        <Box id="helpModalBody" className={contentStyle || classes.contentStyle}>
                            {helpText}
                        </Box>
                    </ModalContent>
                </Fragment>
            </MaterialModal>
        </div>
    );
};

export default HelpButton;