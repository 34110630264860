import React from "react";
import { Box, Typography, createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footerText: {
            fontFamily: 'Spartan MB',
            fontSize: '0.6875rem',
            textAlign: 'center',
            color: theme.footer.textColor,
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left'
            },
        },
        footer: {
            background: theme.verticalMargins.footerBackgroundColour,
            position: 'relative',
            left: '0',
            bottom: '0',
            [theme.breakpoints.down(450)]: {
                marginTop: '70px',
                padding: '9px 16px 100px 16px',
            },
            [theme.breakpoints.up(450)]: {
                padding: '9px 16px 100px 16px',
            },
            [theme.breakpoints.up('md')]: {
                padding: '9px 60px 70px 60px',
            },
            [theme.breakpoints.up('lg')]: {
                padding: '9px 175px 70px 175px',
            },
            [theme.breakpoints.up(1600)]: {
                padding: '9px 380px 70px 380px',
            },
        },
        footerLinksContainer: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                marginBottom: '35px',
                marginTop: '40px',
            },
        },
        footerLink: {
            fontFamily: 'Spartan MB SemiBold',
            fontSize: '0.8125rem',
            textAlign: 'center',
            color: theme.footer.textColor,
            textDecoration: 'none',
            margin: '12px 18px',
            [theme.breakpoints.down('sm')]: {
                margin: '7px 18px',
            },
        },
    })
);

const Footer = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <footer className={classes.footer}>
            <Box className={classes.footerLinksContainer}>
                <a id="policyWording" target='_blank' href={theme.metadata.contentManagedLinks.footerLinks.policyWordingUrl} className={classes.footerLink}>Policy Wording</a>
                <a id="insuranceServices" target='_blank' href={theme.metadata.contentManagedLinks.footerLinks.aboutOurInsuranceServicesUrl} className={classes.footerLink}>About our Insurance Services</a>
                <a id="insurancePID" target='_blank' href={theme.metadata.contentManagedLinks.footerLinks.insuranceProductInformationDocumentUrl} className={classes.footerLink}>Insurance Product Information Document</a>
                <a id="privacyPolicy" target='_blank' href={theme.metadata.contentManagedLinks.footerLinks.privacyPolicyUrl} className={classes.footerLink}>Privacy Policy</a>
            </Box>
            <Typography id="footerWording" className={classes.footerText}>
                Copyright © 2020 Uinsure Ltd. Registered in England and Wales No. 06046870. This home insurance is arranged and administered by Uinsure Limited.
                Uinsure Limited is authorised and regulated by the Financial Conduct Authority (Firm Reference Number 463689).
                Registered address: 8 St John Street, Manchester, M3 4DU. For policies purchased online, you will not receive advice or a recommendation from us.
                We may ask some questions to narrow down the selection of products that we will provide details on.
                You will then need to make your own choice about how to proceed.
            </Typography>
        </footer>
    );
};

export default Footer;