import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from 'react';
import MaterialButton from "./MaterialButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            fontFamily: "Spartan MB Bold",
            border: 0,
            width: '100%',
            height: '50px',
            padding: theme.spacing(1, 0),
            margin: theme.spacing(2, 0)
        },
        buttonEnabled: {
            backgroundColor: theme.buttons.primary,
            color: theme.palette.primary.contrastText,
            boxShadow: theme.metadata.disableButtonDropShadows ? '' : '0px 9px 8px rgba(0,201,175,0.22)',
            '&:focus, &:hover': {
                backgroundColor: theme.buttons.primary,
            },
        },
    })
);

type Props = {
    children: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    testId?: string;
};

const ContinueButton = ({ children, onClick, disabled, testId }: Props) => {
    const classes = useStyles();
    const classList = [];
    if (!disabled) classList.push(classes.buttonEnabled);

    return (
        <MaterialButton
            testId={testId}
            className={`${classes.button} ${classList.join(" ")}`}
            disabled={disabled}
            onClick={onClick!}
        >
            {children}
        </MaterialButton>
    );
};

export default ContinueButton;