import React from 'react';

interface Props {
    icon: string;
    cssProperties?: string;
};

const Icon = (props: Props) => (
    <span className={`icon-${props.icon} ${props.cssProperties}`}></span>
);

export default Icon;