import { createStyles, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useCookies } from 'react-cookie';
import ContinueButton from "./ContinueButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bar: {
            color: theme.header.textColor,
            position: 'fixed',
            minHeight: '50px',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: theme.header.backgroundColor,
            padding: theme.spacing(0, 3),
            [theme.breakpoints.only('xs')]: {
                padding: theme.spacing(2),
            },
            '& p, & button, & a': {
                fontFamily: 'Spartan MB',
                fontSize: '0.875rem',
            },
        },
        textDiv: {
            alignSelf: 'center',
        },
        buttonDiv: {
            textAlign: 'right',
            [theme.breakpoints.up('sm')]: {
                '& button': {
                    width: '200px',
                    maxWidth: '100%',
                },
            }
        },
    })
);

const CookieBar = () => {
    const classes = useStyles();
    const [cookies, setCookie] = useCookies(['dismissCookieBar']);

    const handleCookiePolicyCloseClick = () => {
        const cookieExpiryDate = new Date();
        cookieExpiryDate.setMonth(cookieExpiryDate.getMonth() + 1);
        setCookie('dismissCookieBar', true, {
            expires: cookieExpiryDate
        });
    };

    return (
        <>
            {
                !cookies['dismissCookieBar'] &&
                <div className={classes.bar}>
                    <Grid id="cookieBar" container>
                        <Grid item xs={12} sm={9} className={classes.textDiv}>
                            <Typography>
                                This website uses cookies to ensure you get the best experience from our website.&nbsp;
                                <Link id="clickForCookiePolicy" target="_blank" href="/cookiepolicy">Learn more</Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} className={classes.buttonDiv}>
                            <ContinueButton testId="gotIt" onClick={handleCookiePolicyCloseClick}>Got it!</ContinueButton>
                        </Grid>
                    </Grid>
                </div>
            }
        </>
    );
};

export default CookieBar;