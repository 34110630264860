import { Box, createStyles, Grid, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import ContinueButton from "../Controls/ContinueButton";
import MaterialButton from "../Controls/MaterialButton";
import MaterialModal from "../Controls/MaterialModal";
declare const LC_API: any;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        eligibilityModalContent: {
            color: theme.palette.text.secondary,
            margin: theme.spacing(4, 2),
            [theme.breakpoints.up(500)]: {
                margin: theme.spacing(4, 4),
            },
            '& h1': {
                fontFamily: 'Spartan MB ExtraBold',
                fontSize: '1.875rem',
                lineHeight: "2.125rem",
                marginBottom: '30px',
            },
            '& h2': {
                fontFamily: 'Spartan MB ExtraBold',
                fontSize: '1.5625rem',
                lineHeight: '1.875rem',
                marginBottom: '20px',
            },
            '& p': {
                fontFamily: 'Spartan MB ExtraBold',
                fontSize: '1rem',
                color: theme.palette.text.primary,
                marginBottom: '16px',
            },
        },
        liveChatButton: {
            border: `3px solid ${theme.buttons.primary}`,
            fontFamily: "Spartan MB ExtraBold",
            color: theme.buttons.primary,
            width: '100%',
            height: '50px',
            padding: theme.spacing(1, 0),
            margin: theme.spacing(2, 0),
            boxShadow: theme.metadata.disableButtonDropShadows ? '' : '0px 9px 8px rgba(0,201,175,0.22)',
        },
        closeModal: {
            backgroundColor: theme.buttons.secondary,
            borderColor: theme.buttons.secondary,
            fontSize: '0.875rem',
            fontFamily: "Spartan MB Bold",
            color: theme.palette.primary.contrastText,
            width: '100%',
            padding: '6px',
            margin: theme.spacing(3, 0, 1),
            '&:focus, &:hover': {
                backgroundColor: theme.buttons.secondary,
            },
        },
    })
);

interface Props {
    open: boolean;
    title: string;
    subTitle?: string;
    content: string | JSX.Element;
    closeButtonLabel?: string;
    showCloseButton?: boolean;
    onClose?: any;
};

const ContactModal = ({ open, title, subTitle, content, closeButtonLabel, showCloseButton, onClose }: Props) => {
    const classes = useStyles();
    const theme = useTheme();

    const handleLiveChat = () => {
        if (typeof (LC_API) !== 'undefined') {
            LC_API.open_chat_window();
        }
    };

    return (
        <MaterialModal
            open={open}
            onClose={onClose}
        >
            <>
                <Box className={classes.eligibilityModalContent}>
                    <Typography variant="h1">{title}</Typography>
                    {subTitle &&
                        <Typography id="contactModalSubtitle" variant="h2">{subTitle}</Typography>
                    }
                    <Typography id="contactModalContent">{content}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <form action={`tel:${theme.metadata.phoneNumber}`}>
                                <ContinueButton testId="callUs">Call us</ContinueButton>
                            </form>
                        </Grid>
                        <Grid item xs={6}>
                            <MaterialButton onClick={handleLiveChat} className={classes.liveChatButton}>Live Chat</MaterialButton>
                        </Grid>
                    </Grid>
                    {showCloseButton && closeButtonLabel &&
                        <MaterialButton testId="returnToQuestion" className={classes.closeModal} onClick={onClose}>{closeButtonLabel}</MaterialButton>
                    }
                </Box>
            </>
        </MaterialModal>
    );
};

export default ContactModal;