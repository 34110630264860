import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core';

interface Props {
    children: JSX.Element | JSX.Element[];
    id?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalTitle: {
            margin: theme.spacing(1, 0),
            fontFamily: "Spartan MB Bold",
            fontSize: "20px",
        },
    }),
);

const ModalTitle = (props: Props) => {
    const classes = useStyles();
    return (
        <h2 className={classes.modalTitle} id={props.id}>{props.children}</h2>
    )
};

export default ModalTitle;