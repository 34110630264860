import { createStyles, Theme, makeStyles } from "@material-ui/core";
import React from 'react';
import Icon from "../Icon";

interface Props {
    showClose?: boolean;
    children: JSX.Element;
    onClick?: (_: React.MouseEvent<HTMLElement>) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalHeader: {
            borderBottom: "1px solid #d3d3d3",
            padding: theme.spacing(2, 4),
        },
        closeButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        iconClose: {
            fontSize: "1rem",
            fontWeight: "bold",
            color: theme.palette.secondary.main,
        },
    }),
);

const ModalHeader = ({ showClose, onClick, children }: Props) => {
    const classes = useStyles();
    return (
        <div className={classes.modalHeader}>
            {showClose &&
                <div className={classes.closeButtonContainer}>
                    <a onClick={onClick} data-testid="modal-close">
                        <Icon icon="Close" cssProperties={classes.iconClose} />
                    </a>
                </div>
            }
            {children}
        </div>
    )
};

export default ModalHeader;