import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface Props {
    children: JSX.Element | JSX.Element[];
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContent: {
            padding: theme.spacing(0, 2, 4, 2),
        },
    })
);

const ModalContent = (props: Props) => {
    const classes = useStyles();
    return (
        <div className={classes.modalContent}>
            {props.children}
        </div>
    )
};

export default ModalContent;