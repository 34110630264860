import { Backdrop, createStyles, Fade, makeStyles, Modal, Theme } from "@material-ui/core";
import React from 'react';

const getModalStyle = () => {
    return {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            maxWidth: "520px;",
            width: "95%",
            backgroundColor: theme.palette.background.paper,
            borderRadius: "10px",
            "&:focus": {
                "outline": "none",
            },
        },
    })
);

interface Props {
    children: JSX.Element | JSX.Element[];
    open: boolean;
    onClose?: (_: React.MouseEvent<HTMLElement>) => void;
};

const MaterialModal = (props: Props) => {
    const classes = useStyles();
    const modalStyle = getModalStyle();

    return (
        <Modal
            data-testid="modal"
            aria-labelledby="simple-modal-title"
            open={props.open}
            onClose={props.onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 100,
            }}
        >
            <Fade in={props.open}>
                <div style={modalStyle} className={classes.paper}>
                    {props.children}
                </div>
            </Fade>
        </Modal>
    )
};

export default MaterialModal;