import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            fontFamily: "Spartan MB SemiBold",
            fontSize: '1rem',
            color: theme.palette.text.primary,
            borderColor: '#e5e5e5',
            border: 'solid',
            borderWidth: 2,
            paddingRight: 60,
            paddingLeft: 60,
            paddingTop: 4,
            paddingBottom: 4,
            textTransform: 'none',
            '&:focus': {
                backgroundColor: '#f8f8fa'
            }
        },
        buttonDisabled: {
            backgroundColor: '#d6d6d8',
        },
        buttonSelected: {
            borderColor: 'transparent',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.buttons.secondary,
            '&:focus, &:hover': {
                backgroundColor: theme.buttons.secondary,
            }
        }
    }),
);

type Props = {
    children: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    className?: string;
    disabled?: boolean;
    selected?: boolean;
    testId?: string;
    type?: "button" | "reset" | "submit";
};

const MaterialButton = ({ children, onClick, className, disabled, selected, testId, type }: Props) => {
    const classes = useStyles();
    const additionalClasses = [];
    if (className) additionalClasses.push(className);
    if (disabled) additionalClasses.push(classes.buttonDisabled);
    if (selected) additionalClasses.push(classes.buttonSelected);

    return (
        <Button
            data-testid={testId || "material-button"}
            className={`${classes.button} ${additionalClasses.join(" ")}`}
            disabled={disabled}
            onClick={onClick}
            type={type || "submit"}
        >
            {children}
        </Button>
    );
};

export default MaterialButton;